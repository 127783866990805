import React, { useState, useEffect, Fragment } from 'react';
import './App.css';
import AppNavBar from './components/AppNavBar/AppNavBar.js';
import Splash from './components/Splash/Splash.js';
import ClientSearch from './views/ClientSearch/ClientSearch.js';
import HelpGuides from './views/Services/HelpGuides.js';
import ServiceQuality from './views/Services/ServiceQuality.js';
import FundExplorer from './views/FundExplorer/FundExplorer/FundExplorer.js';
import ContactUs from './views/ContactUs/ContactUs.js';
import NotFound from './views/NotFound/NotFound.js';
import Services from './views/Services/Services.js';
import ServiceOverview from './views/Services/ServiceOverview.js';
import StandardReports from './views/Services/StandardReports.js';
import RegulatedReports from './views/Services/RegulatedReports.js';
import NewFundReportRequest from './views/Services/NewFundReportRequest.js';
import Forms from './views/Services/Forms.js';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  useParams
} from "react-router-dom";

import {
  fetchSettings,
  fetchServiceConfiguration
} from './services/api.js';

import {
  getSettings
} from './services/settings';



const App = () => {

  const [settings, setSettings] = useState(null);
  const [serviceOptions, setServiceOptions] = useState(null);
  const [reactSettings, setReactSettings] = useState(null);

  const [error, setError] = useState(null);

  const AddError = (systemError, userError) => {
    setError({ systemError, userError });
  }

  useEffect(() => {
    async function getAppServiceSettings() {
      var payload = await fetchSettings();
      setSettings({ ...payload.data });
    }

    async function getReactSettings() {
      let payload = await getSettings();
      setReactSettings({ ...payload});
    }

    async function getServiceConfiguration() {
      var payload = await fetchServiceConfiguration();
      setServiceOptions({ ...payload.data });
    }

    getReactSettings().catch(err => AddError(err, null));
    getAppServiceSettings().catch(err => AddError(err, null));
    getServiceConfiguration().catch(err => AddError(err, null));
  }, []);

  const displayIfReady = (view, showFeature) => {
    return (serviceOptions && settings && reactSettings) && (!showFeature || settings[showFeature]) ? view : <Fragment></Fragment>;
  }

  const ServiceNavigator = ({serviceOptions}) => {
    let { level1, level2 } = useParams();

    let serviceInfo = serviceOptions &&  serviceOptions[level1] &&  serviceOptions[level1][level2] ?  {level1 : {...serviceOptions[level1], name: level1}, level2 : {...serviceOptions[level1][level2], name: level2}} : null;

    var specialCases = {
      "Standardised Reports/Standard Reports" : <StandardReports serviceInfo={serviceInfo}></StandardReports>,
      "Standardised Reports/Regulated Reports" : <RegulatedReports serviceInfo={serviceInfo}></RegulatedReports>,
      "Custom Solutions/Custom Fund Reports" : <NewFundReportRequest serviceInfo={serviceInfo} requestName="New Custom Fund Report Request"></NewFundReportRequest>,     
      "Onboarding and Integration/Forms" : <Forms serviceInfo={serviceInfo}></Forms>

    }

    var specialCase = specialCases[level1 + "/" + level2]

    if (!serviceInfo)
      return <NotFound></NotFound>;
    if (specialCase)
      return specialCase;
    else 
      return (<ServiceOverview serviceInfo={serviceInfo}></ServiceOverview>);
  }

  return (
    <Fragment>
      <Splash status={serviceOptions && settings && reactSettings ? "ready" : error ? "error" : "loading"}></Splash>

      <Router>
              <AppNavBar settings={settings} reactSettings={reactSettings}></AppNavBar>
        <Switch>
          <Route path="/services/" exact={true} children={displayIfReady(<Services serviceOptions={serviceOptions} settings={settings}></Services>)}/>
          <Route path="/fundexplorer/" exact={true} children={displayIfReady(<FundExplorer></FundExplorer>, "showFundExplorer")}/>
          <Route path="/helpguides/" exact={true} children={displayIfReady(<HelpGuides></HelpGuides>)}/>
          <Route path="/servicequality/" exact={true} children={displayIfReady(<ServiceQuality></ServiceQuality>)}/>          
          <Route path="/contactus/" exact={true} children={displayIfReady(<ContactUs></ContactUs>)}/>
          <Route path="/clientsearch/" exact={true} children={displayIfReady(<ClientSearch></ClientSearch>)}/>
          <Route path="/services/:level1/:level2/"  exact={true} children={displayIfReady(<ServiceNavigator serviceOptions={serviceOptions}></ServiceNavigator>)}/>
          <Route path="/" exact={true}>
            <Redirect to="/services"></Redirect>
          </Route>
          <Route>
            <NotFound></NotFound>
          </Route>
        </Switch>
      </Router>
    </Fragment>
  )
}

export default App;


