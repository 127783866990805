import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Services.css'
import PageWrapper from './PageWrapper.js';
import './ServiceQuality.css';
import { ragStatuses, ragFullDesc } from '../../reportingRagStatus.js'

import {
  fetchServiceQuality
} from '../../services/api.js';

const ServiceQuality = () => {

  const [serviceQuality, setServiceQuality] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState({ loading: false, error: null });


  useEffect(() => {
    async function getServiceQuality() {
      try {
        setLoadingStatus({ loading: true });
        var payload = await fetchServiceQuality();
        setServiceQuality([...payload.data]);
        setLoadingStatus({ loading: false });
      }
      catch (err) {
        setLoadingStatus({ loading: false, error: { systemError: "Failed to initialise" } });
      }
    }

    getServiceQuality();
  }, []);

  return (
    <PageWrapper page={"Landing"} isLoading={loadingStatus.loading} error={loadingStatus.error}>
      <Row>
        <Col>
          <h3>
            <Link to="/">Our Services</Link>
            {" > "}
            {"Service Quality"}
          </h3>
          <p>Take a look at our <a href="https://savingsandinvestments.sharepoint.com/:p:/r/sites/ClientReportingDatafile/Shared%20Documents/Reporting%20Dashboard/Reporting%20Dashboard.pptx?d=w6ae5be1d15fd41fcbe4b69f07297ebd3&csf=1&web=1&e=HukqR7" target="_blank">Service Quality</a> dashboard.</p>

          <p>Here is a real time view of our bespoke reporting delivery over the past few months.</p>

          <table className="table">
            <thead>
              <tr className="TableRow">
                <td className="HeaderCell" width="30%">Report Date</td>
                <td className="HeaderCell" width="12%">Reports</td>
                <td className="HeaderCell" width="12%" title="Not yet sent">Pending</td>
                <td className="HeaderCell" width="12%" title="Sent to client">Complete</td>
                <td className="HeaderCell" width="12%" title={ragFullDesc(ragStatuses["R"])}>R</td>
                <td className="HeaderCell" width="12%" title={ragFullDesc(ragStatuses["A"])}>A</td>
                <td className="HeaderCell" width="12%" title={ragFullDesc(ragStatuses["G"])}>G</td>
              </tr>
            </thead>
            <tbody>
              {serviceQuality ? serviceQuality.map((c, index) =>
                <tr className="TableRow" key={index}>
                  <td className="ValueCell">
                    {new Date(c.reportDate).toISOString().slice(0, 10)}
                  </td>
                  <td className="ValueCell">
                    {c.reports}
                  </td>
                  <td className="ValueCell">
                    {c.pending}
                  </td>
                  <td className="ValueCell">
                    {c.complete}
                  </td>
                  <td className="ValueCell">
                    {c.red}
                  </td>
                  <td className="ValueCell">
                    {c.amber}
                  </td>
                  <td className="ValueCell">
                    {c.green}
                  </td>
                </tr>
              ) : <Fragment></Fragment>}
            </tbody>
          </table>

        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
        </Col>
      </Row>
    </PageWrapper>
  );

}

export default ServiceQuality;
