import { msalApiFetch } from '../msalConfig.js'
import { getSettings } from './settings.js';

async function doRequest(path, nullStatuses, options = {}, endpoint) {

	let response;

	var settings = await getSettings();

	const pathConstruct = !path.includes('http') ? `${settings.baseUrl}${path}` : path;

	response = await msalApiFetch(fetch, `${pathConstruct}`, options, endpoint);

	if (response.ok) {
		return { data: await response.json(), isError: false };
	} else if (nullStatuses && nullStatuses.includes(response.status)) {
		return { data: null, isError: false }
	} else if (response.status === 400) {
		return { data: await response.json(), isError: true }
	}

	throw response;
}

export const fetchSettings = () => doRequest(`/api/settings`);
export const fetchServiceConfiguration = () => doRequest(`/api/serviceConfiguration`);
export const fetchClientReports = () => doRequest(`/api/clientReports`);
export const fetchServiceQuality = () => doRequest(`/api/serviceQuality`);
export const performLog = (payload) => doRequest(`/api/logs`, [], {
	method: 'POST',
	cache: 'no-cache',
	headers: {
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(payload)
});
export const fetchFundExplorer = (fundCode, date) => doRequest(`/api/fundExplorer?fundCode=${fundCode}&date=${date}`);
export const fetchFundExplorerFunds = () => doRequest(`/api/fundExplorer/funds`);
export const fetchFundExplorerFundDates = (fundCode) => doRequest(`/api/fundExplorer/reportingDates?fundCode=${fundCode}`);

export const fetchDataQuality = (date) => doRequest(`/api/dataQuality?date=${date}`);
export const fetchDataQualityDates = (fundCode) => doRequest(`/api/dataQuality/reportingDates`);