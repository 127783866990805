import React, { Fragment, useEffect } from 'react';
import Banner from '../../components/Banner/Banner.js';
import LoadingOverlay from 'react-loading-overlay';
import './PageWrapper.css';
import { Container } from "react-bootstrap";

import { useLocation } from 'react-router-dom'

import {
  performLog
} from '../../services/api.js';

const PageWrapper = (props) => {

  let location = useLocation()

  useEffect(
    () => {
      performLog({ page: location.pathname });
    },
    [location]
  )

  return (
    <Fragment>
      <LoadingOverlay
        active={!props.error && props.isLoading}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            background: 'rgba(255, 255, 255, 0.7)'
          }
          )
          , spinner: (base) => ({
            ...base,
            '& svg circle': {
              stroke: '#055A60'
            }
          }
          )
        }}
      >
        {props.page ? <Banner page={props.page}></Banner> : <Fragment />}
        <div className={props.page ? "Explorer" : "ExplorerNoBanner"}>
          {props.error && props.error.systemError ? <div className="alert alert-danger">
            <Container>
              {props.error.systemError}
            </Container>
          </div> :
            <Container className={props.className}>
              {props.children}
            </Container>
          }
        </div>
      </LoadingOverlay>
    </Fragment>
  );
}

export default PageWrapper;






