import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.js';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MsalProvider } from "@azure/msal-react";
import {getSettings} from './services/settings.js';
import * as MsalAuth from "./authConfig";

(async () => {
	const msalInstance = await MsalAuth.authConfig(await getSettings());

	ReactDOM.render(
		<ErrorBoundary>
			<MsalProvider instance={msalInstance}>
				<App />
			</MsalProvider>
		</ErrorBoundary>, root);

})();