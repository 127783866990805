import {getSettings} from './services/settings.js';
import {authToken} from "./authConfig";

export async function callApiWithMsal(accessToken, endpoint, fetch, url, options) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    headers.append("Authorization", bearer);

    if(options.headers){
      options.headers["Authorization"] = bearer;
    }else{
      options.headers = headers;
    }
    
    return await fetch(url, options);
}

export const msalApiFetch = async (fetch, url, options, endpoint) => {
	var settings = await getSettings();
	var idToken = await authToken();
  
  return await callApiWithMsal(idToken, endpoint || settings.clientId, fetch, url, options); 	
}