import React from 'react';
import { Col, Row } from "react-bootstrap";
import Hierarchy from './Hierarchy.js'
import './ServiceOverview.css';
import { Link } from "react-router-dom";
import PageWrapper from './PageWrapper.js';

const Forms = ({ serviceInfo }) => {

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const legalEmail = () => {
    return <a href="mailto:legal.services@mandg.co.uk?subject=NDA%20request" target="_blank">legal.services@mandg.co.uk</a>;
  }

  const gocsEmail = () => {
    return <a href="mailto:GOCS@mandg.com?subject=NDA%20request" target="_blank">GOCS@mandg.com</a>;
  }

  return (
    <PageWrapper
      page={serviceInfo.level1.name}>
      <Row>
        <Col>
          <h3>
            <Link to="/">Our Services</Link>
            {" > "}
            {serviceInfo.level1.name}
            {" > "}
            {serviceInfo.level2.name}
          </h3>
        </Col>
      </Row>


      <Row>
        <Col>
          <p>{serviceInfo.level2.message}</p>
          <p>Please send all completed NDA request forms to {gocsEmail()}.</p>
          <p><strong>Note:</strong> for specific NDAs not covered above (e.g. Basel III or bespoke data requirements) please contact {gocsEmail()} and {legalEmail()} who will change the purpose and content accordingly.</p>
        </Col>
      </Row>

      <Row>
        {Object.entries(groupBy(serviceInfo.level2.list, 'column')).map(([key, value]) =>
          <Col className="Column" xs={12} md={8} key={"column" + key} >
            {<Hierarchy index={serviceInfo.level1.index} data={value} level1={serviceInfo.level1.name} level2={serviceInfo.level2.name}></Hierarchy>}
          </Col>
        )
        }
      </Row>
    </PageWrapper>

  );
}

export default Forms;
