import React from 'react';
import { Col, Row } from "react-bootstrap";
import './ContactUs.css';
import { Link } from "react-router-dom";
import PageWrapper from '../../views/Services/PageWrapper.js';

const ContactUs = () => {

  const url = "mailto:Retail.client.reporting.data@mandg.co.uk?subject=Standard%20Data%20sets%20Query";

  return (
    <PageWrapper page="Contact">
          <Row>
            <Col>
              <h3>
                <span>
                  <Link to="/">Our Services</Link>
                  {" > "}
            Contact Us</span>
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div >
                <p>Please contact our reporting team if you have any queries or suggestions on how we may improve this site.</p>
                <a href={url} target="_blank">retail.client.reporting.data@mandg.co.uk</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>

            </Col>
          </Row>
    </PageWrapper>
  );
}

export default ContactUs;
