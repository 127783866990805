import React from 'react';
import './Banner.css';
// import { LazyImage } from "react-lazy-images";

const Banner = ({ page }) => {

  
  const themes = {
    "Landing": "PictureBanner-Landing",
    "Contact": "PictureBanner-Contact",
    "Search": "PictureBanner-Search",
    "Standardised Reports": "PictureBanner-0",
    "Custom Solutions": "PictureBanner-1",
    "Onboarding and Integration": "PictureBanner-2",
    "Links": "PictureBanner-3",
    "FundExplorer": "PictureBanner-Explorer",
    "DataQuality": "PictureBanner-Explorer",
    
  }



  return (
    <div className={page !== null ? "PictureBanner " + themes[page] : "PictureBanner " + themes["Landing"]}>
    </div>
  );

}

export default Banner;
