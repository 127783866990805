import React, { useState, Fragment } from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Services.css'
import PageWrapper from './PageWrapper.js';


import {
  performLog
} from '../../services/api.js';


const Services = ({ serviceOptions, settings }) => {

  const [index, setIndex] = useState("Landing");

  const optionBoxParentCss = (index) => {
    return "OptionBox OptionBox-Parent OptionBox-Parent-" + index;
  }
  const optionBoxChildCss = (index) => {
    return "OptionBox AppLinkHide OptionBox-Child OptionBox-Child-" + index;
  }

  const OptionBoxParent = ({ option, title }) => {
    return (

      <div className={optionBoxParentCss(option.value.index)}>
        <span>{title}</span>
      </div>
    );
  }

  const OptionBoxChild = ({ option, title, onSelect, url, icon }) => {
    const innerText = () => { return <span>{title}{icon ? <span>&nbsp;&nbsp;<FontAwesomeIcon icon={icon} /> </span> : ""}</span> }

    const logActivity = (url) => {
      performLog({ page: "services", level1: title, url });
    }

    return (
      <Fragment>
        {onSelect ? <Link className={optionBoxChildCss(option.value.index)} to={"/services/" + option.key + "/" + title + "/"}>{innerText()}</Link> :
          <a onClick={() => logActivity(url)} className={optionBoxChildCss(option.value.index)} href={url} target="_blank">{innerText()}</a>}
      </Fragment>
    );
  }

  return (
    <PageWrapper page={index}>
      <Row>
        <Col>
          <h3>Our Services</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="IntroBox">
            <p className="">Welcome to the Reporting and Service Catalogue for all your client reporting needs, giving you access to anything from standard reports all the way to bespoke reporting solutions.</p>
            <ul>
              {settings.showFundExplorer ? <li>Visit <Link to="/fundexplorer">Fund Explorer</Link> for a current and historic view of fund information</li> : <Fragment></Fragment>}
              <li>See <Link to="/clientsearch">Client Search</Link> for the reports we are currently delivering to our customers (now includes RAG status)</li>
              <li>Take a look at our <Link to="/servicequality">Service Quality</Link> dashboard</li>
              <li>If you have any issues you can raise them <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=fRZCqo1vzkW2VdJF75faZooNuh6QZZpGp3OiLpIHAARUNVlDVkRLTlZHNTc1NEVYSTZZWFJQQkI2Vi4u" target="_blank">here</a> <span className="NewBadge">New</span></li>                         
              <li>View <Link to="/helpguides">Help Guides</Link> for a video demo and other useful artifacts</li>
            
            
            </ul>
          </div>
        </Col>
      </Row>
      <Row>
        {Object.entries(serviceOptions).map(([key1, value]) =>
          <Col xs={12} md={3} className="OptionBoxColumn" key={value.index} onMouseOver={() => setIndex(key1)} onMouseLeave={() => setIndex("Landing")} >
            <OptionBoxParent option={{ key: key1, value }} key={key1} title={key1}></OptionBoxParent>
            {Object.entries(value).map(([key2, value2]) => key2 !== "index" && key2 !== "tooltip" ? <OptionBoxChild onSelect={value2.list ? { key1, key2 } : null} option={{ key: key1, value }} key={key1 + key2} title={key2} icon={!value2.list ? faExternalLinkAlt : null} url={value2.list === undefined ? value2.url : null}></OptionBoxChild> : <div key={key1 + key2}></div>)}
          </Col>
        )

        }
      </Row>
     
    </PageWrapper>
  );

}

export default Services;
