import React from 'react';
import './NotFound.css';
import { Link } from "react-router-dom";


const NotFound = () => {
  return (
    <div className="ExplorerNoBanner">
      <div className="NotFound">
        <span className="NotFound-Text">Page Not Found</span>
        <br></br>
        <Link to={"/"}>Go to home page</Link>
      </div>
    </div>
  );
}

export default NotFound;
