import React from 'react';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './Services.css'
import PageWrapper from './PageWrapper.js';
import Iframe from 'react-iframe';
import './HelpGuides.css';

const HelpGuides = () => {

  const guides = [
    {
      title: "REPS Overview",
      url : "https://web.microsoftstream.com/video/eedf515d-042d-41b8-9584-82521634a20c",
      embeddedUrl: "https://web.microsoftstream.com/embed/video/eedf515d-042d-41b8-9584-82521634a20c?autoplay=false&amp;showinfo=true"
    }
  ]

  return (
    <PageWrapper page={"Landing"}>
      <Row>
        <Col>
          <h3>
            <Link to="/">Our Services</Link>
            {" > "}
            {"Help Guides"}
          </h3>
          <p>As REPS continues to evolve useful videos and 'how to guides' will be added below.</p>

        </Col>
      </Row>
      <Row>
        {guides.map((guide, index) =>
          <Col xs={12} md={12} key={index}>
            <div className="Guide-Item">
              <h4 className="Guide-Header">{guide.title} &nbsp;<a className="AppLinkHide" href={guide.url} target="_blank"><FontAwesomeIcon icon={faExternalLinkAlt} /></a></h4>
              <div className="News">
                <Iframe
                  url={guide.embeddedUrl}
                  allowFullScreen={true}
                  className="Guide-Content"
                  display="initial"
                  position="relative" />
              </div>
            </div>
          </Col>
        )}
      </Row>
    </PageWrapper>
  );

}

export default HelpGuides;
