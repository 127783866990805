import React, {Fragment} from 'react';
import './AppNavBar.css';
import Navbar from 'react-bootstrap/Navbar'
import logo from '../../resources/MG_pos_rgb_1.0.png';
import { Link } from "react-router-dom";


const AppNavBar = ({settings,reactSettings}) => {
  return (
    <Navbar fixed="top" expand="lg" className="navbar-fixed-top" >
      <Link className="navbar-brand LogoBrand" to="/">
        <img
          alt=""
          src={logo}
          className="d-inline-block align-top AppNavBarLogo"
        />
      </Link>
      <Link className="navbar-brand " to="/">
        <div className="AppTitle-Teal">
          <h1><span className="AppTitle-AppName">REPS</span></h1>
          <h5 className="AppTitle-SubHeader">Reporting and Service Catalogue</h5>
        </div>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="menu" className="justify-content-end">
        <div className="MenuOptions">
          {settings && settings.showFundExplorer ? <Link className="MenuOption navbar-nav ml-auto" to="/fundexplorer/">Fund Explorer</Link> : <Fragment></Fragment>}
          {settings && settings.showDataQuality ? <a className="MenuOption navbar-nav ml-auto" href={reactSettings.mfeProductFlowBaseUrl+"/"+reactSettings.mfeProductFlowDataSetStatus} target="_blank">Data Quality</a> : <Fragment></Fragment>}
          <Link className="MenuOption navbar-nav ml-auto" to="/clientsearch/">Client Search</Link>
          <Link className="MenuOption navbar-nav ml-auto" to="/servicequality/">Service Quality</Link>
          <Link className="MenuOption navbar-nav ml-auto" to="/services/Onboarding and Integration/New Fund Onboarding/">Onboarding</Link>
          <Link className="MenuOption navbar-nav ml-auto" to="/services/Standardised Reports/Raise a Change Request/">Change Request</Link>
          <a className="MenuOption navbar-nav ml-auto" href="https://forms.office.com/Pages/ResponsePage.aspx?id=fRZCqo1vzkW2VdJF75faZooNuh6QZZpGp3OiLpIHAARUNVlDVkRLTlZHNTc1NEVYSTZZWFJQQkI2Vi4u" target="_blank">Report Issue</a>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );

}

export default AppNavBar;
