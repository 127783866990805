import React from 'react';
import { Col, Row } from "react-bootstrap";
import Hierarchy from './Hierarchy.js'
import './ServiceOverview.css';
import { Link } from "react-router-dom";
import PageWrapper from './PageWrapper.js';


const RegulatedReports = ({ serviceInfo }) => {

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  return (
    <PageWrapper page={serviceInfo.level1.name}>
      <Row>
        <Col>
          <h3>
            <Link to="/">Our Services</Link>
            {" > "}
            {serviceInfo.level1.name}
            {" > "}
            {serviceInfo.level2.name}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{serviceInfo.level2.message}</p>
          <p><strong>Note:</strong> reports linked below are for example purposes.  Click on the search icon to find specific reports.</p>
        </Col>
      </Row>

      <Row>
        {Object.entries(groupBy(serviceInfo.level2.list, 'column')).map(([key, value]) =>
          <Col className="Column" xs={12} md={6} key={"column" + key} >
            {<Hierarchy index={serviceInfo.level1.index} data={value} level1={serviceInfo.level1.name} level2={serviceInfo.level2.name}></Hierarchy>}
          </Col>
        )
        }
      </Row>
    </PageWrapper>

  );
}

export default RegulatedReports;
