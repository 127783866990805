import React, { Fragment } from 'react';
import './WidgetWrapper.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

const WidgetWrapper = (props) => {

  let { children, exportConfig, title } = props;

  return (
    <div className="WidgetWrapper">
      <div className="Widget-TopBar">
        <h3 className="Widget-Header">{title}</h3>
        {exportConfig ?
          <div>
            <a className="Widget-Download" href={`${exportConfig.dataExportUrl}`} target="_blank">
              <FontAwesomeIcon icon={faFileExport} />
            </a>

            </div>
          : <Fragment></Fragment>
        }
      </div>
      {children}
    </div>
  );
}

export default WidgetWrapper;
