import React from 'react';
import './Splash.css';

const Splash = ({ status }) => {
  return (
    <div className={status === "ready" ? "Splash-slide" : "Splash"}>
      <div className="LeftPanel">
        <div className="Splash-logo">
        </div>
        <div className="Splash-intro">
            <span className="Splash-Title">REPS</span>
            <span className="Splash-SubHeader">Reporting and Service Catalogue</span>
            {status === "error" ? <div className="Splash-Message">Ut oh, there is a problem ... please refresh the page. <br></br>If the problem persists contact Support.</div> : <div className="Splash-Message"></div>}
        </div>

      </div>
      <div className="RightPanel">

      </div>
    </div>
  );
}

export default Splash;
