import React, { Fragment } from 'react';
import './DataSetKeyFactsTable.css'

const DataSetKeyFactsTable = ({ title, dataSet, source, footNotes }) => {

  return (
    <Fragment>
      <h3>{title}</h3>
      {dataSet ?
        <table className="DataSetKeyFactsTable">
          <tbody>
            {dataSet.rows.map((row, index) =>
              Object.entries(dataSet.columns).map(([key, value]) => <tr key={"row_" + key}>
                <td  title={value.tooltip} width="30%">{value.label}</td>
                <td>{row.fields[key].type === "Value" ? <Fragment> {row.fields[key].formattedValue}</Fragment> : <a href={row.fields[key].link} target="_blank">{row.fields[key].text}</a>}</td>
              </tr>)
            )}
          </tbody>
        </table> : <Fragment></Fragment>}
      <span className="Footnote">{source}</span>

    </Fragment>
  );
}

export default DataSetKeyFactsTable;
