import React, { Fragment } from 'react';
import WidgetWrapper from './WidgetWrapper.js'
import DocumentLinks from './DocumentLinks.js'
import DataSetKeyFactsTable from './DataSetKeyFactsTable.js'
import DataSetStandardTable from './DataSetStandardTable.js'
import './SectionBuilder.css'

const SectionBuilder = ({ item, index, fundCode }) => {

  const NoRecordsMsg = () => <p>No data to display.</p>

  var exportConfig = (item) => {
    return item.dataExportUrl ? {
      dataExportUrl: item.dataExportUrl,
      dataSetTypeId: item.dataSetTypeId
    } : null;
  }

  return (

    item.type === "text" ?
      <WidgetWrapper title={item.title}>
        {item.text ? <p>{item.text}</p> : <NoRecordsMsg />}
      </WidgetWrapper>
      :
      item.type === "error" ?
        <WidgetWrapper title={item.title}>
          {item.text ? <p>{item.text}</p> : <NoRecordsMsg />}
        </WidgetWrapper>
        :
        item.type === "keyFacts" ?
          <WidgetWrapper title={item.title} exportConfig={exportConfig(item)}>
            {item.dataSet && item.dataSet.rows && item.dataSet.rows.length !== 0 ? <DataSetKeyFactsTable dataSet={item.dataSet} source={item.source} footNotes={item.footNotes}></DataSetKeyFactsTable> : <NoRecordsMsg />}
          </WidgetWrapper>
          :
          item.type === "standardTable" ?
            <WidgetWrapper title={item.title} exportConfig={exportConfig(item)}>
              {item.dataSet && item.dataSet.rows && item.dataSet.rows.length !== 0 ? <DataSetStandardTable dataSet={item.dataSet} source={item.source} footNotes={item.footNotes} showHeaders={item.showHeaders}></DataSetStandardTable> : <NoRecordsMsg />}
            </WidgetWrapper>
            :
            item.type === "commentaryWrapper" ?
              <Fragment >
                {item.types && item.types.length !== 0 ?
                  item.types.map((type, index0) =>
                    <Fragment key={"comm_" + index0}>
                      <div className="Section-SubHeader">{type.commentaryType}</div>
                      {type.sections.map((section, index2) =>
                        <div key={"item_" + index + "_text_" + index2} className="CommentarySectionText">
                          <p dangerouslySetInnerHTML={{ __html: section.text }}></p>
                        </div>
                      )}
                    </Fragment>)
                  : <NoRecordsMsg />}
              </Fragment>
              :
              item.type === "comingSoon" ?
                <div className="SectionTextWrapper">
                  <p>We hold a lot of data including fees, prices, distributions, ratings and much more. In time we will make further data available.</p>
                </div>
                :
                item.type === "documentLinks" ?
                  <div className="SectionTextWrapper">
                    <DocumentLinks fundCode={fundCode} />
                  </div>
                  :
                  <p>{item.type} not known</p>

  );
}

export default SectionBuilder;
