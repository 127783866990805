import React, { Fragment } from 'react';
import './Hierarchy.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faInfoCircle, faArrowCircleRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import Tooltip from 'react-tooltip-lite';

import { Link } from "react-router-dom";

import {
  performLog
} from '../../services/api.js';


const HierarchyBox = ({ level, title, url, hasNodes, index, level1, level2, tooltip, type, searchUrl }) => {

  const hierarchyRowCss = () => {
    return "HierarchyRow HierarchyRow-" + index;
  }

  const hierarchyTitleCss = (level) => {
    return "HierarchyTitle-" + level;
  }

  const logActivity = (url,type) => {
    if (type ==="internal"){
    }
    else {
      performLog({ page: "service", level1, level2, level3: title, url });
    }
  }


  return (
    <tr className={hierarchyRowCss()}>
      <td className={hierarchyTitleCss(level)}>
        <a className="AppLinkHide" onClick={() => url ? logActivity(url, type) : () => { }} target="_blank" href={url}>{title}{hasNodes ? " +" : ""}</a>
      </td>
      <td>
        {url && type === "internal" ?  
        <Link className="AppLinkHide"  onClick={() => url ? logActivity(url, type) : () => { }} to={url}><FontAwesomeIcon className="FileIcon" icon={faArrowCircleRight} /></Link> :
        url ? <a className="AppLinkHide"  onClick={() => url ? logActivity(url, type) : () => { }} target="_blank" href={url}><FontAwesomeIcon className="FileIcon" icon={faFile} /></a>
        : <Fragment></Fragment>}
      </td>
      <td>
        {searchUrl ?  
        <a className="AppLinkHide"  onClick={() => url ? logActivity(searchUrl, type) : () => { }} target="_blank" href={searchUrl}><FontAwesomeIcon className="FileIcon" icon={faSearch} /></a>
        : <Fragment></Fragment>}
      </td>



      <td>
        {tooltip ?
          <Tooltip
            content={(
              <div className="TooltipMessage">
                <b>{title}</b><br></br>
                {tooltip}
              </div>
            )}
            direction="right"
            tagName="span"
            className="target"
          >
            <div className="InfoCell">
            <FontAwesomeIcon className="InfoIcon" icon={faInfoCircle} />
            </div>
          </Tooltip> : <Fragment></Fragment>}
      </td>



    </tr>


  );
}


const Hierarchy = ({ index, data, level1, level2 }) => {

  return (
    <table className="Table">
      <thead>
        <tr>
          <td width="70%"></td>
          <td width="10%"></td>
          <td width="10%"></td>
          <td width="10%"></td>
        </tr>
      </thead>
      <tbody>
        {data.map(d =>
          <Fragment key={d.label} >
            <HierarchyBox title={d.label} level={1} url={d.url} hasNodes={d.nodes} index={index} tooltip={d.tooltip} level1={level1} level2={level2}  type={d.type}  searchUrl={d.searchUrl}></HierarchyBox>
            {d.nodes ? d.nodes.map(l2 =>
              <Fragment key={d.label + l2.label} >
                <HierarchyBox url={l2.url} title={l2.label} level={2} hasNodes={l2.nodes} index={index} tooltip={l2.tooltip} level1={level1} level2={level2}  type={l2.type} searchUrl={l2.searchUrl}></HierarchyBox>
                {l2.nodes ? l2.nodes.map(l3 => <HierarchyBox key={d.label + l2.label + l3.label} url={l3.url} title={l3.label} level={3} index={index} tooltip={l3.tooltip} level1={level1} level2={level2} type={l3.type} searchUrl={l3.searchUrl}></HierarchyBox>) : <Fragment></Fragment>}
              </Fragment >
            ) : <Fragment ></Fragment >}
          </Fragment >
        )}
      </tbody>
    </table>
  );
}

export default Hierarchy;
