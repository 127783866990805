import React, { Fragment } from 'react';
import './DataSetStandardTable.css'

const DataSetStandardTable = ({title, dataSet, source, showHeaders}) => {


  const TdCssName = (row, column) =>{
    //TextAlign should be moved to a style function and map left and middle
    var css = column.dataType === "Number" || column.textAlign === "Right" ?  " TextRight " : "";
    css+= (row.isTotal ? " GrandTotalRow " : "");
    return css;
  }

  return (
    <Fragment>
      <h3>{title}</h3>
      {dataSet ?       
      <table className="DataSetStandardTable">
        {showHeaders ? 
        <thead>
          <tr>
            {Object.entries(dataSet.columns).map(([key,value])=><td title={value.tooltip} className={value.dataType === "Number" || value.textAlign === "Right" ?  "TextRight" : ""} width={value.width ? value.width : "auto"} key={"col_"+key}>{value.label}</td>)}
          </tr>
        </thead> : <Fragment></Fragment>}
        <tbody>
        {dataSet.rows.map((row,index)=><tr key={"row_"+index} className={row.isTotal ? "GrandTotalRow" : ""}>
          {Object.entries(dataSet.columns).map(([key,value])=>
          <td className={TdCssName(row,value)} key={"row_"+index+"_col_"+key}>{row.fields[key].type ==="Value" ? <Fragment> {row.fields[key].formattedValue}</Fragment> : <a href={row.fields[key].link} target="_blank">{row.fields[key].text}</a> }</td>
          )}
        </tr>)}
        </tbody>
      </table> : <Fragment></Fragment>}
      <span className="Footnote">{source}</span>

    </Fragment>
  );
}

export default DataSetStandardTable;
