import React, { Fragment } from 'react';
import { Buffer } from 'buffer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {
  performLog
} from '../../services/api.js';


const DocumentLinks = ({fundCode}) => {


  const getKiboSearchUrl = (fundCode) => {
    var f = {"SearchMode":0,"FacetMode":0,"SearchFilters":{"fundCode":{"Values":[fundCode.toLowerCase()],"FilterType":"Text"}},"Page":null,"PageSize":null,"SearchText":null,"LatestOnly":true,"IncludeExpired":null,"HighlightPostTag":null,"HighlightPreTag":null,"OrderBy":null,"Facets":null,"ResultFields":null,"HighlightCount":null,"ContentLength":null,"ModifiedAfter":null};
    var text = JSON.stringify(f);
    var filters = Buffer.from(text).toString('base64');
  return "https://kibo.mandg.com/?store=all&filters=" + filters;
  
  }

  return (
    <Fragment>
      {fundCode ? <p>Search for all fund related documents in Kibo &nbsp;<a className=""  onClick={() => fundCode ? performLog({ page: "fundexplorer", level1: fundCode, url:getKiboSearchUrl(fundCode) }) : () => { }} target="_blank" href={getKiboSearchUrl(fundCode)}><FontAwesomeIcon className="FileIcon" icon={faSearch} /></a></p> : <Fragment></Fragment>}
    </Fragment>
  );

}

export default DocumentLinks;
