import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import './ClientSearch.css';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import { Link } from "react-router-dom";
import PageWrapper from '../../views/Services/PageWrapper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ragStatuses, ragFullDesc, RagTooltip } from '../../reportingRagStatus.js'

import {
  fetchClientReports
} from '../../services/api.js';


const circleStyle = (score) => {
  var obj = {};
  obj.color = ragStatuses[score].color;
  return obj;
}

const HistoryModal = ({ report, setShow, show }) => {

  return (
    <div>
      <Modal className="ClientSearch-Modal" show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <h3>{report.reportName}</h3>
        </Modal.Header>
        <Modal.Body>

          <table className="table">
            <thead>
              <tr className="TableRow">
                <td className="HeaderCell" width="15%">Report Date</td>
                <td className="HeaderCell" width="10%">RAG</td>
                <td className="HeaderCell" width="10%">Elapsed Days</td>
                <td className="HeaderCell" width="30%">Note</td>
                <td className="HeaderCell" width="20%">Document</td>
              </tr>
            </thead>
            <tbody>
              {report && report.history ? report.history.map((c, index) =>
                <tr className="TableRow" key={"HistoryRow" + index}>
                  <td className="ValueCell">
                    {new Date(c.reportDate).toISOString().slice(0, 10)}
                  </td>
                  <td className="ValueCell">
                    <FontAwesomeIcon icon={faCircle} style={circleStyle(c.rag)} title={ragFullDesc(ragStatuses[c.rag])} />
                  </td>
                  <td className="ValueCell">
                    {c.elapsedBusinessDays}
                  </td>
                  <td className="ValueCell">
                    {c.note}
                  </td>
                  <td className="ValueCell">
                    {c.link && c.link.startsWith("http") ?
                      <a className="btn btn-secondary ClientSearch-OpenDoc-Button" href={c.link} target="_blank">Open</a> :
                      c.link}
                  </td>
                </tr>
              ) : <Fragment></Fragment>}
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}


const SequenceScore = ({ id, history }) => {



  return (
    <div>
      {history.map((s, index) =>
        <span key={"id" + id + "_score_" + index} title={"Status: " + ragFullDesc(ragStatuses[s.rag]) + "\r\nReporting Date: " + new Date(s.reportDate).toISOString().slice(0, 10) + "\r\nElapsed Days: " + s.elapsedBusinessDays}><FontAwesomeIcon icon={faCircle} style={circleStyle(s.rag)} /> </span>
      )}

    </div>
  )
}

const ClientSearch = () => {

  const [clientReports, setClientReports] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({ loading: false, error: null });

  useEffect(() => {
    async function getClientReports() {
      try {
        setLoadingStatus({ loading: true });
        var payload = await fetchClientReports();
        setClientReports([...payload.data]);
        setLoadingStatus({ loading: false });
      }
      catch (err) {
        setLoadingStatus({ loading: false, error: { systemError: "Failed to initialise" } });
      }
    }

    getClientReports();
  }, []);


  var options = clientReports ? [...clientReports] : null;

  return (
    <PageWrapper page="Search" className="ClientSearch" isLoading={loadingStatus.loading} error={loadingStatus.error}>
      <Row>
        <Col>
          <h3>
            <span><Link to="/">Our Services</Link>
              {" > "}
              Client Search</span>
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <p >Search for the client below to see the reports we are currently producing for them:</p>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="SearchBox">
            {options ?
              <Typeahead
                id="onclear-example"
                options={options}
                onChange={client => setSelectedClient(client)}

                placeholder="Choose a client...">
                {({ onClear, selected }) => (
                  <div className="ClearButton">
                    {!!selected.length && <ClearButton onClick={onClear} />}
                  </div>
                )}
              </Typeahead> : <Fragment></Fragment>}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {selectedClient && selectedClient.length !== 0 ?
            <Fragment>
              <HistoryModal show={showHistory} setShow={setShowHistory} report={selectedReport}></HistoryModal>

              <table className="table">
                <thead>
                  <tr className="TableRow">
                    <td className="HeaderCell" width="25%">Report</td>
                    <td className="HeaderCell" width="15%">Region</td>
                    <td className="HeaderCell" width="10%">Frequency</td>
                    <td className="HeaderCell" width="10%" title="Business Day">BD</td>
                    <td className="HeaderCell" width="15%" title={RagTooltip()}>RAG</td>
                    <td className="HeaderCell" width="20%">Document</td>
                  </tr>
                </thead>
                <tbody>
                  {selectedClient.map(r => r.reports.map((c, index) =>
                    <tr className="TableRow" key={index} >
                      <td className="ValueCell">
                        {c.reportName}
                      </td>
                      <td className="ValueCell">
                        {c.region}
                      </td>
                      <td className="ValueCell">
                        {c.frequency}
                      </td>
                      <td className="ValueCell">
                        {c.workingDay}
                      </td>

                      <td className="ValueCell">
                        <SequenceScore id={index} history={c.history.slice(0, 6).sort((a, b) => a.period - b.period)} />
                      </td>
                      <td>
                        {c.history && c.history.length !== 0 ?
                          <Button variant="secondary" onClick={() => {
                            setSelectedReport(c);
                            setShowHistory(true);
                          }}>
                            View
                        </Button> : <Fragment></Fragment>}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Fragment>

            : <Fragment></Fragment>}
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default ClientSearch;
