import React, { } from 'react';
// import AppNavBar from './AppNavBar.js'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false
        }
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    render() {
        if(this.state.hasError) {
            return (
                <div>
                {/* <AppNavBar ></AppNavBar>      */}
                <div className="alert alert-danger">An error has occurred.  Please try again, if the issue persists please contact your support team.</div>
                </div>
                )
        } else {
            return this.props.children
        }
    }
}

export default ErrorBoundary;
