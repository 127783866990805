import { PublicClientApplication } from '@azure/msal-browser'
import {getSettings} from './services/settings.js';
import {EventType, InteractionRequiredAuthError} from '@azure/msal-browser';

let msalInstance;
let settings;

export const authConfig = async (msalSettings) => {
    settings = msalSettings;
    if(msalInstance == null){
        console.log("Reporting Catalog. create msalInstance");
        const msalConfig = {
            auth: {
                clientId: settings.clientId,
                authority: "https://login.microsoftonline.com/".concat(settings.tenant),
                redirectUri: window.location.href,
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true,
            }
        }
        msalInstance = new PublicClientApplication(msalConfig);
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
            }
        }, error=>{
            console.log("addEventCallback failed")
            console.log('error', error);
        }); 
        await msalInstance.handleRedirectPromise()
                    .then(async (tokenResponse) => {
                        if (!tokenResponse) {
                            const accounts = msalInstance.getAllAccounts();
                            if (accounts.length === 0) {
                                // No user signed in
                                console.log("Reporting catalog. handleRedirectPromise, loginRedirect");
                                await msalInstance.loginRedirect();
                            }
                        } else {
                            console.log("Reporting catalog. Set active account");
                            msalInstance.setActiveAccount(tokenResponse.account)
                            return msalInstance;
                        }
                    })
                    .catch(err => {
                        // Handle error
                        console.error(err);
                    });

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
            console.log("Reporting catalog. No user signed in, loginRedirect");
            await msalInstance.loginRedirect();
        }            
           
    }
    return msalInstance;
}

export const authToken = async () =>{
    var msalInstance = await authConfig();
    const activeAccount = msalInstance.getActiveAccount();
    if(!activeAccount){
       await msalInstance.loginRedirect();
    }
    const request = {
        scopes: ["User.Read"],
        account: activeAccount
    };
    let authResult ;
    try {
        // attempt silent acquisition first
        authResult = await msalInstance.acquireTokenSilent(request);
    } catch (error) {
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
            authResult = await msalInstance.acquireTokenPopup(request);
        }
    }
    return authResult.idToken;
}

export const loginRequest = async () => {
    const settings = await getSettings();
    const appIdUri = "api://".concat(settings.tenant, "/", settings.clientId, "/user_impersonation")
    const scopes = {
        scopes: ["User.Read", appIdUri]
    }
    return scopes
}

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};
