import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row } from "react-bootstrap";
import { Link as Link2 } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import './FundExplorer.css'
import SectionBuilder from '../SectionBuilder.js'
import PageWrapper from '../../Services/PageWrapper.js';
import { getSettings } from '../../../services/settings.js';
import {
  fetchFundExplorer,
  fetchFundExplorerFunds,
  fetchFundExplorerFundDates
} from '../../../services/api.js';

import { Link, Element, animateScroll as scroll } from 'react-scroll'

const FundSelector = ({ funds, selectedFund, setSelectedFund, clearSelection }) => {

  const mapFund = () => {
    return funds.map(f => ({ label: f.name + " [" + f.fundCode + "]", data: f }));
  }

  const selectedItem = () => {
    return mapFund().filter(f => f.data.fundCode === selectedFund);
  }

  return (
    <Typeahead
      id="fundSelector"
      options={mapFund()}
      onChange={fund => {
        clearSelection();
        var matchedFund = fund && fund.length !== 0 ? fund[0].data.fundCode : null;
        console.log(fund);
        setSelectedFund(matchedFund);
      }}
      //onChange={fund => console.log(fund)}
      selected={selectedItem()}
      placeholder="Choose a fund...">
    </Typeahead>)
}

const FundExplorer = () => {

  const systemError = "There has been an unexpected problem.  Please refresh the page and if the problem persists then please contact support.";

  const [funds, setFunds] = useState(null);
  const [reportingDates, setReportingDates] = useState(null);
  const [selectedFund, setSelectedFund] = useState(null);
  const [selectedReportingDate, setSelectedReportingDate] = useState(null);
  const [data, setData] = useState(null);
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedSection, setSelectedSection] = useState();

  const clearSelection = () => {
    setData(null);
    setSelectedFund(null);
    setReportingDates(null);
    setSelectedReportingDate(null);
  }

  const addError = (systemError, userError) => {
    setError({ systemError, userError });
  }

  const onSectionChange = (to) => {
    setSelectedSection(to);
  }

  const onSelectedReportingDateChange = (e) => {
    setSelectedReportingDate(e.target.value);
  }

  useEffect(() => {

    async function getData() {
      try {
        setIsLoading(true);
        var fundsResult = await fetchFundExplorerFunds();
        var settingsResult = await getSettings();
        setFunds([...fundsResult.data]);
        // setSelectedFund(fundsResult.data[0].fundCode);
        setSettings(settingsResult);
      }
      catch (err) {
        addError(systemError);
      }
      finally {
        setIsLoading(false);
      }
    }

    getData();
  }, []);

  useEffect(() => {

    async function getFundExplorerFundDates(fundCode) {
      try {
        //setIsLoading(true); //clash with useEffect below
        var result = await fetchFundExplorerFundDates(fundCode);
        setReportingDates([...result.data]);
        setSelectedReportingDate(result.data[0]);
      }
      catch (err) {
        addError(systemError);
      }
      finally {
        //setIsLoading(false); //clash with useEffect below
      }
    }

    if (selectedFund) {
      getFundExplorerFundDates(selectedFund);
    }

  }, [selectedFund]);

  useEffect(() => {

    async function getFundExplorerData(fundCode, reportingDate) {

      try {
        setIsLoading(true);
        var result = await fetchFundExplorer(fundCode, reportingDate);
        setData({ ...result.data });
        setSelectedSection("section_" + result.data.sections[0].name);
        scroll.scrollToTop();
      }
      catch (err) {
        setData(null);
        addError(systemError);
      }
      finally {
        setIsLoading(false);
      }

    }

    if (selectedFund && selectedReportingDate) {
      getFundExplorerData(selectedFund, selectedReportingDate);
    }

  }, [selectedFund, selectedReportingDate]);


  const GetView = () => {

    return data && data.sections && selectedFund ?


      <div className="element" id="containerElement">
        {data.sections.map(section =>
          <Element key={"element_" + section.name} name={"section_" + section.name} onMouseOver={() => setSelectedSection("section_" + section.name)}>
            <div className="Section-Header">{section.name}</div>
            {section.items.map((item, index) => <SectionBuilder key={"item_" + index} item={item} index={index} fundCode={selectedFund}></SectionBuilder>)}
          </Element>
        )}

        <div style={{ cursor: "pointer" }} onClick={() => scroll.scrollToTop()}><div className="Section-Header-Orange">Back to top</div></div>

      </div>



      : <Fragment ></Fragment>
  }

  return (
    <PageWrapper page="FundExplorer" isLoading={isLoading} error={error} className="FundExplorer">
      <Row className="PageHeader">
        <Col>
          <h3>
            <span><Link2 to="/">Our Services</Link2>{" > "}Fund Explorer</span>
          </h3>
          <div className="IntroBox">
            <p>Choose a fund and reporting date to view available data and optionally export to excel with constituent data sets.</p>
            <p className="Warning"><b>Note:</b> The data displayed below may not have been signed off for use by the Client Reporting Data Team so please <a href={"mailto:Retail.client.reporting.data@mandg.co.uk?subject=FundExplorer%20Data%20Use"} target="_blank">check</a> before use.</p>
          </div>
          <hr></hr>
        </Col>
      </Row>

      {settings && funds ?
        <Row className="MainArea">
          <Col className="SideMenuCol" xs={12} md={3}>
            <div className="SideMenu">
              <div className="SideMenu-Sections">
                <h3>Sections</h3>
                {selectedFund && data && data.sections ?
                  data.sections.map((section) =>
                    <Link key={"section" + section.name} className="AppLinkHide" activeClass="active" to={"section_" + section.name} spy={true} smooth={true} offset={-450} duration={500} onSetActive={onSectionChange}>
                      <div className={"FundNavigation-Item" + (("section_" + section.name) === selectedSection ? " FundNavigation-Item-Selected" : "")}><span>{section.name}</span></div>
                    </Link>
                  ) : <em>No fund selected</em>}
              </div>
            </div>
          </Col>
          <Col xs={12} md={9}>
            <div className="Content">
              <div className="Content-Header">

                <div className="ControlGroup">
                  <label className="FormLabel">Fund:</label>
                  <span id="fundSelector"><FundSelector funds={funds} selectedFund={selectedFund} setSelectedFund={setSelectedFund} clearSelection={clearSelection}></FundSelector></span>
                  
                  {selectedFund || reportingDates ?
                      <button id="#clearSelection" className="AppButton" onClick={clearSelection} title="Clear Selection">X</button>
                     : <Fragment></Fragment>}
                </div>
                {reportingDates && data ?
                  <div className="ControlGroup">
                    <label className="FormLabel">Effective Date:</label>
                    <select className="FormControl" value={selectedReportingDate ? selectedReportingDate : ""} onChange={onSelectedReportingDateChange}>
                      {reportingDates.map((reportingDate) => <option key={reportingDate} value={reportingDate}>{reportingDate}</option>)}
                    </select>
                  </div> : <Fragment></Fragment>}

                <hr></hr>
              </div>

              {selectedFund ? GetView() : <em>No fund selected</em>}
            </div>
          </Col>
        </Row>


        : <Fragment></Fragment>
      }


    </PageWrapper>
  );

}

export default FundExplorer;
